/* if (typeof module === "object") {
  module.exports = {
    env: {
      URL_HOST: "http://localhost:3001",
      URL_LARAVEL: "http://localhost:8000",
      URL_FRONTEND: "http://localhost:3001",
      NODE_ENV: "local",
      SSL_CERT: "https://ejconchat.smartichat.de/certificates/fullchain.pem",
      SSL_KEY: "https://ejconchat.smartichat.de/certificates/privkey.pem",
    },
  };
} */

if (typeof module === "object") {
  module.exports = {
    env: {
      URL_HOST: "https://chat.smartichat.com",
      URL_LARAVEL: "https://admin.smartichat.com",
      URL_FRONTEND: "https://chat.smartichat.com",
      NODE_ENV: "production",
      SSL_CERT: "https://chat.smartichat.com/certificates/fullchain.pem",
      SSL_KEY: "https://chat.smartichat.com/certificates/privkey.pem",
    },
  };
}
