if (typeof module === "object") {
  module.exports = {
    German: {
      support: "Support-Bot",
      language: "de",
      welcome: "Herzlich Willkommen! Wie kann ich Ihnen helfen?",
      fileSize: "Ihre Datei sollte nicht größer als 5 MB sein.",
      fileType:
        "Ihr Upload muss einem der folgenden Dateitypen entsprechen: JPG, JPEG, PNG, PDF ",
      rating: "Chatsitzung bewerten",
      zufrieden: "Wie zufrieden Sind Sie?",
      message: "Nachricht",
      file: "Datei",
      send: "Senden",
      afterSend: "senden",
      ticket: "Ticket erstellen",
      title: "Betreff",
      description: "Beschreibung",
      emailTo: "Email an",
      sendAttachment: "Anhang senden",
      noAssignedRooms: "Keine zugewiesene Chaträume",
      name: "Name",
      lang: "Sprache",
      action: "Action",
      open: "Öffnen",
      welcomeTitle: "Herzlich willkommen!",
      welcomeText1:
        "Wir sind für Sie da und freuen uns auf Ihre Fragen oder Ihr Feedback.",
      welcomeText2: "Hinweis zum Datenschutz",
      welcomeText3:
        "Mit der Nutzung unseres Chat-Systems erklären Sie sich mit der folgenden Datenschutzerklärung einverstanden:",
      welcomeText4:
        "Die während des Chats gesammelten Daten, einschließlich der Seite, von der aus Sie auf den Chat zugegriffen haben, werden ausschließlich zur Beantwortung Ihrer Fragen verwendet. Chat-Verläufe werden zu Trainings- und Bewertungszwecken gespeichert. Für weitere Informationen besuchen Sie bitte unsere Datenschutzseite.",
      newSession: "Neue Sitzung",
      oldSession: "Vorherige Sitzung",
      reqConfirmCode: "Bestätigungscode anfordern",
      email: "E-Mail",
      cancel: "Abbrechen",
      giveConfirmCode: "Bestätigungscode eingeben",
      confirmCode: "Bestätigungscode",
      enter: "Beitreten",
      personalInfo: "Nur noch einige persönliche Infos",
      chooseGender: "Anrede auswählen",
      mr: "Herr",
      mrs: "Frau",
      lastName: "Nachname",
      startChat: "Chat starten",
      online: "Online",
      offline: "Offline",
      allAssignedRooms: "Alle zugewiesene Chaträume",
      leaveRoom: "Chatraum verlassen",
      stopChat: "Chat beenden",
      sendHistory: "Chatverlauf an mich",
      rateChat: "Chatsitzung bewerten",
      please: "Bitte",
      privacy: "Datenschutz",
      accept: "akzeptieren",
      accepted: "akzeptiert",
      joinedRoom: "ist dem Chatraum beigetreten",
      leftRoom: "hat den Chatraum verlassen",
      chatEnded: "Die chat-Sitzung wurde beendet",
      yourMessage: "Ihre Nachricht",
      chooseEmoji: "Emoji Auswählen",
      welcomeBack: "Willkommen zurück",
      howHelp: "Wie kann ich Ihnen heute helfen?",
      goodDay: "Guten Tag",
      zustimmung: "Ihre Zustimung unserer Datenschutzrichtlinie fehlt.",
      missingMsg: "Ihre Nachricht fehlt.",
      missingTitle: "Der Betreff Ihrer Nachricht fehlt.",
      chooseLang: "Bitte wählen Sie eine Sprache aus.",
      chooseDep: "Bitte wählen Sie eine Abteilung aus.",
      invalidEmail: "Die angegebene E-Mail ist nicht gültig.",
      missingEmail: "Ihre E-Mail Adresse fehlt.",
      missingLname: "Ihr Nachname fehlt.",
      missingGender: "Ihre Anrede fehlt.",
      missingCode: "Der Code fehlt.",
      chooseOpt: "Bitte wählen Sie eine Option aus.",
      missingDesc: "Die Beschreibung fehlt.",
      missingAttach: "Die Anlage Fehlt.",
      supportFormDesc:
        "Bitte füllen Sie das Formular aus, um eine E-Mail an support zu senden.",
      supportUnavailable:
        "Leider sind wir momentan nicht da. Bitte füllen Sie das Formular aus, um eine E-Mail an support zu senden.",
      gladlyConnect: "Gerne verbinde ich Sie mit einem Mitarbeiter.",
      chooseDepLang: "Bitte wählen Sie eine Abteilung und eine Sprache aus.",
      cancelledReq:
        "Die Anfrage wurde von Ihnen abgesagt. Wie kann ich Ihnen weiterhelfen?",
    },
    English: {
      support: "Support-Bot",
      language: "en",
      welcome: "Welcome! How can I help you?",
      fileSize: "Your file should not be larger than 5 MB.",
      fileType:
        "Your upload must be one of the following file types: JPG, JPEG, PNG, PDF",
      rating: "Rate chat session",
      zufrieden: "How satisfied are you?",
      message: "Message",
      file: "File",
      send: "Send",
      afterSend: "",
      ticket: "Create ticket",
      title: "Subject",
      description: "Description",
      emailTo: "Email",
      sendAttachment: "Send attachment",
      noAssignedRooms: "No assigned chat rooms",
      name: "Name",
      lang: "Language",
      action: "Action",
      open: "Open",
      welcomeTitle: "Welcome!",
      welcomeText1:
        "We are here for you and look forward to your questions or feedback.",
      welcomeText2: "Privacy notice",
      welcomeText3:
        "By using our chat system, you agree to the following privacy policy:",
      welcomeText4:
        "The data collected during the chat, including the page from which you accessed the chat, is used only to answer your questions. Chat histories are stored for training and evaluation purposes. For more information, visit out privacy policy page.",
      newSession: "New session",
      oldSession: "Previous session",
      reqConfirmCode: "Request confirmation code",
      email: "E-mail",
      cancel: "Cancel",
      giveConfirmCode: "Enter confirmation code",
      confirmCode: "Confirmation code",
      enter: "Enter",
      personalInfo: "Just a few more personal details",
      chooseGender: "Choose salutation",
      mr: "Mr.",
      mrs: "Mrs.",
      lastName: "Last name",
      startChat: "Start chat",
      online: "Online",
      offline: "Offline",
      allAssignedRooms: "All assigned chat rooms",
      leaveRoom: "Leave chat room",
      stopChat: "End chat",
      sendHistory: "Send chat history to me",
      rateChat: "Rate chat session",
      please: "Please",
      privacy: "Privacy",
      accept: "accept",
      accepted: "accepted",
      joinedRoom: "joined the chat room",
      leftRoom: "left the chat room",
      chatEnded: "The chat session has ended",
      yourMessage: "Your message",
      chooseEmoji: "Choose emoji",
      welcomeBack: "Welcome back",
      howHelp: "How can I help you today?",
      goodDay: "Good day",
      zustimmung: "Your consent to our privacy policy is missing.",
      missingMsg: "Your message is missing.",
      missingTitle: "The subject of your message is missing.",
      chooseLang: "Please select a language.",
      chooseDep: "Please select a department.",
      invalidEmail: "The email provided is not valid.",
      missingEmail: "Your email address is missing.",
      missingLname: "Your last name is missing.",
      missingGender: "Your salutation is missing.",
      missingCode: "The code is missing.",
      chooseOpt: "Please select an option.",
      missingDesc: "The description is missing.",
      missingAttach: "The attachment is missing.",
      supportFormDesc: "Please fill out the form to send an email to support.",
      supportUnavailable:
        "Unfortunately, we are currently unavailable. Please fill out the form to send an email to support.",
      gladlyConnect: "I will be happy to connect you with a representative.",
      chooseDepLang: "Please select a department and a language.",
      cancelledReq:
        "The request was canceled by you. How can I help you further?",
    },
    French: {
      support: "Support-Bot",
      language: "fr",
      welcome: "Bienvenue ! Comment puis-je vous aider ?",
      fileSize: "Votre fichier ne doit pas dépasser 5 Mo.",
      fileType:
        "Votre téléchargement doit être l'un des types de fichiers suivants : JPG, JPEG, PNG, PDF",
      rating: "Évaluer la session de chat",
      zufrieden: "Quelle est votre satisfaction ?",
      message: "Message",
      file: "Fichier",
      send: "Envoyer",
      afterSend: "",
      ticket: "Créer un ticket",
      title: "Sujet",
      description: "Description",
      emailTo: "Email à",
      sendAttachment: "Envoyer une pièce jointe",
      noAssignedRooms: "Aucune salle de chat assignée",
      name: "Nom",
      lang: "Langue",
      action: "Action",
      open: "Ouvrir",
      welcomeTitle: "Bienvenue !",
      welcomeText1:
        "Nous sommes là pour vous et nous attendons vos questions ou vos commentaires.",
      welcomeText2: "Avis de confidentialité",
      welcomeText3:
        "En utilisant notre système de chat, vous acceptez la politique de confidentialité suivante :",
      welcomeText4:
        "Les données collectées pendant le chat, y compris la page depuis laquelle vous avez accédé au chat, sont utilisées uniquement pour répondre à vos questions. Les historiques de chat sont conservés à des fins de formation et d'évaluation. Pour plus d'informations, veuillez consulter notre page de politique de confidentialité.",
      newSession: "Nouvelle session",
      oldSession: "Session précédente",
      reqConfirmCode: "Demander un code de confirmation",
      email: "E-mail",
      cancel: "Annuler",
      giveConfirmCode: "Entrer le code de confirmation",
      confirmCode: "Code de confirmation",
      enter: "Entrer",
      personalInfo: "Encore quelques informations personnelles",
      chooseGender: "Choisissez une civilité",
      mr: "Monsieur",
      mrs: "Madame",
      lastName: "Nom de famille",
      startChat: "Démarrer le chat",
      online: "En ligne",
      offline: "Hors ligne",
      allAssignedRooms: "Toutes les salles de chat assignées",
      leaveRoom: "Quitter la salle de chat",
      stopChat: "Terminer le chat",
      sendHistory: "Envoyer l'historique du chat",
      rateChat: "Évaluer la session de chat",
      please: "S'il vous plaît",
      privacy: "Confidentialité",
      accept: "accepter",
      accepted: "accepté",
      joinedRoom: "a rejoint la salle de chat",
      leftRoom: "a quitté la salle de chat",
      chatEnded: "La session de chat est terminée",
      yourMessage: "Votre message",
      chooseEmoji: "Choisir un emoji",
      welcomeBack: "Bon retour",
      howHelp: "Comment puis-je vous aider aujourd'hui ?",
      goodDay: "Bonjour",
      zustimmung:
        "Votre consentement à notre politique de confidentialité manque.",
      missingMsg: "Votre message est manquant.",
      missingTitle: "Le sujet de votre message est manquant.",
      chooseLang: "Veuillez sélectionner une langue.",
      chooseDep: "Veuillez sélectionner un département.",
      invalidEmail: "L'email fourni n'est pas valide.",
      missingEmail: "Votre adresse e-mail est manquante.",
      missingLname: "Votre nom de famille est manquant.",
      missingGender: "Votre civilité est manquante.",
      missingCode: "Le code est manquant.",
      chooseOpt: "Veuillez sélectionner une option.",
      missingDesc: "La description est manquante.",
      missingAttach: "La pièce jointe est manquante.",
      supportFormDesc:
        "Veuillez remplir le formulaire pour envoyer un e-mail au support.",
      supportUnavailable:
        "Malheureusement, nous ne sommes pas disponibles pour le moment. Veuillez remplir le formulaire pour envoyer un e-mail au support.",
      gladlyConnect: "Je serai heureux de vous connecter avec un représentant.",
      chooseDepLang: "Veuillez sélectionner un département et une langue.",
      cancelledReq:
        "La demande a été annulée par vous. Comment puis-je vous aider davantage ?",
    },
    Swedish: {
      support: "Support-Bot",
      language: "sv",
      welcome: "Välkommen! Hur kan jag hjälpa dig?",
      fileSize: "Din fil får inte vara större än 5 MB.",
      fileType:
        "Din uppladdning måste vara av följande filtyper: JPG, JPEG, PNG, PDF",
      rating: "Betygsätt chatsession",
      zufrieden: "Hur nöjd är du?",
      message: "Meddelande",
      file: "Fil",
      send: "Skicka",
      afterSend: "",
      ticket: "Skapa ärende",
      title: "Ämne",
      description: "Beskrivning",
      emailTo: "E-post",
      sendAttachment: "Skicka bifogad fil",
      noAssignedRooms: "Inga tilldelade chattrum",
      name: "Namn",
      lang: "Språk",
      action: "Åtgärd",
      open: "Öppen",
      welcomeTitle: "Välkommen!",
      welcomeText1:
        "Vi finns här för dig och ser fram emot dina frågor eller feedback.",
      welcomeText2: "Sekretessmeddelande",
      welcomeText3:
        "Genom att använda vårt chattystem godkänner du följande sekretesspolicy:",
      welcomeText4:
        "De uppgifter som samlas in under chatten, inklusive sidan du använde för att komma åt chatten, används endast för att besvara dina frågor. Chattloggar sparas för utbildnings- och utvärderingsändamål. För mer information, besök vår sida om integritetspolicy.",
      newSession: "Ny session",
      oldSession: "Tidigare session",
      reqConfirmCode: "Begär bekräftelsekod",
      email: "E-post",
      cancel: "Avbryt",
      giveConfirmCode: "Ange bekräftelsekod",
      confirmCode: "Bekräftelsekod",
      enter: "Ange",
      personalInfo: "Bara några fler personliga detaljer",
      chooseGender: "Välj tilltal",
      mr: "Herr",
      mrs: "Fru",
      lastName: "Efternamn",
      startChat: "Starta chatt",
      online: "Online",
      offline: "Offline",
      allAssignedRooms: "Alla tilldelade chattrum",
      leaveRoom: "Lämna chattrum",
      stopChat: "Avsluta chatt",
      sendHistory: "Skicka chattens historik till mig",
      rateChat: "Betygsätt chatsession",
      please: "Vänligen",
      privacy: "Sekretess",
      accept: "acceptera",
      accepted: "accepterad",
      joinedRoom: "anslöt till chattrummet",
      leftRoom: "lämnade chattrummet",
      chatEnded: "Chatsessionen har avslutats",
      yourMessage: "Ditt meddelande",
      chooseEmoji: "Välj emoji",
      welcomeBack: "Välkommen tillbaka",
      howHelp: "Hur kan jag hjälpa dig idag?",
      goodDay: "God dag",
      zustimmung: "Ditt samtycke till vår sekretesspolicy saknas.",
      missingMsg: "Ditt meddelande saknas.",
      missingTitle: "Ämnet för ditt meddelande saknas.",
      chooseLang: "Vänligen välj ett språk.",
      chooseDep: "Vänligen välj en avdelning.",
      invalidEmail: "Den angivna e-postadressen är ogiltig.",
      missingEmail: "Din e-postadress saknas.",
      missingLname: "Ditt efternamn saknas.",
      missingGender: "Ditt tilltal saknas.",
      missingCode: "Koden saknas.",
      chooseOpt: "Vänligen välj ett alternativ.",
      missingDesc: "Beskrivningen saknas.",
      missingAttach: "Den bifogade filen saknas.",
      supportFormDesc:
        "Vänligen fyll i formuläret för att skicka ett e-postmeddelande till support.",
      supportUnavailable:
        "Tyvärr är vi för närvarande inte tillgängliga. Vänligen fyll i formuläret för att skicka ett e-postmeddelande till support.",
      gladlyConnect: "Jag kopplar dig gärna till en representant.",
      chooseDepLang: "Vänligen välj en avdelning och ett språk.",
      cancelledReq: "Förfrågan avbröts av dig. Hur kan jag hjälpa dig vidare?",
    },
  };
}
